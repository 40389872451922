import type { FC } from "react";
import React, { useEffect } from "react";
import ConsentForm from "../../ConsentForm/ConsentForm";
import { tracker, useBloc } from "src/state/state";
import UserCubit from "src/state/UserCubit/UserCubit";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import Branding from "../../Branding/Branding";
import InDialog from "../../InDialog/InDialog";
import translate from "src/lib/translate";

const MandatoryMissingConsentsOverlay: FC = () => {
  const [show, setShow] = React.useState(false);
  const authStage = useUserAuthStage();
  const [, { hasConsentsNotAccepted }] = useBloc(UserCubit);
  const [, { hasActiveSubscription }] = useBloc(SubscriptionCubit);

  useEffect(() => {
    if (
      hasConsentsNotAccepted === true &&
      (authStage === "fullAuth" ||
        (authStage === "partialAuth" && hasActiveSubscription === true))
    ) {
      tracker.track("Mandatory consent dialog opened", {
        data: {
          authStage: authStage,
          hasActiveSubscription: hasActiveSubscription,
          hasConsentsNotAccepted: hasConsentsNotAccepted
        }
      });
      setShow(true);
    } else {
      setShow(false);
    }
  }, [hasConsentsNotAccepted, authStage, hasActiveSubscription]);

  return (
    <InDialog
      isOpen={show}
      title={translate("consents_update_title")}
      hideHeader
      backdropDismiss={false}
      showClose={false}
      height="630px"
      width="640px"
    >
      <ConsentForm
        header={
          <Branding
            style={{
              marginBottom: "var(--space-lg)"
            }}
          />
        }
      />
    </InDialog>
  );
};

export default MandatoryMissingConsentsOverlay;
